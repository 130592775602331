export default {
  primary: {
    backgroundColor: 'primary',
    color: 'white',
    border: 'solid 2px',
    borderColor: 'dark',
    borderRadius: '0px',
    textTransform: 'uppercase',
    letterSpacing: '1px',
    fontFamily: 'heading',
    fontWeight: 'bold',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    fontSize: ['1rem', '', '1.2rem'],
    padding: '1rem 2rem',
    transition: 'all ease-in-out 0.5s',
    border: 'solid 1px',
    borderColor: 'primary',
    ':hover': {
      backgroundColor: 'black',
      borderColor: 'black',
      color: 'light'
    }
  },
  secondary: {
    color: 'light',
    border: 'solid 2px',
    borderColor: 'secondary',
    borderRadius: '0px',
    textTransform: 'uppercase',
    letterSpacing: '1px',
    fontFamily: 'body',
    fontWeight: 'bold',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    fontSize: ['0.9rem', '', '1rem'],
    padding: '1rem',
    transition: 'all ease-in-out 0.5s',
    ':hover': {
      backgroundColor: 'secondary',
      borderColor: 'white'
    }
  }
}
