export default {
  // ? ==========================
  // ? =====  Multilocation  ====
  // ? ==========================

  multiLocation: {},

  // ? ===================
  // ? =====  Layout  ====
  // ? ===================

  navigation: {
    '.container': {
      backgroundColor: 'transparent',
      padding: ['0.5rem', '0.5rem', '1rem', '1rem'],
      zIndex: '1001',

      textShadow: '1px 1px 1px black'
    },

    '.smallNavMenu > div': {
      border: 'none',
      '.navItem': {
        color: 'inherit',
        padding: ['0.5rem', '0.5rem', '0.5rem'],
        a: {
          fontSize: ['1rem', '1rem', '1rem', '1rem'],
          letterSpacing: '0px'
        }
      }
    },

    '.navItem': {
      color: 'inherit',
      margin: '0rem',
      padding: ['0.75rem', '0.75rem', '1rem'],
      width: '100%',
      flexGrow: '1',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      ':hover': {
        color: 'inherit'
      }
    },
    '.hamburger': {
      mr: '1.5rem',
      '> div': {
        backgroundColor: 'white',
        boxShadow: '1px 1px 2px black'
      }
    },

    '.navMenuLogo': {
      position: 'static',
      width: ['100%', '', '100%'],
      padding: ['0.5rem', '', '2rem'],
      borderBottom: '1px solid',
      borderColor: 'secondary',
      margin: '0rem',
      img: {
        maxHeight: ['55px', '', '100px', '']
      }
    },
    '.navMenuOpen': {
      backgroundColor: 'background',
      width: ['90%', '', '40%'],
      justifyContent: ['flex-start', 'flex-start', 'flex-start'],
      alignItems: 'flex-start',
      padding: '0rem',
      '.seperatorLine': {
        width: '100%',
        maxWidth: 'unset',
        borderColor: 'secondary',
        margin: '1rem 0rem 0rem'
      },
      '.navItem': {
        padding: ['0.75rem 1rem', '0.75rem 1rem', '1rem 1rem', '0.75rem 2rem', '0.75rem 2.5rem', '1rem 3rem'],
        justifyContent: 'flex-start',
        a: {
          fontSize: ['1rem', '1.25rem', '1.5rem', '1.75rem']
        }
      }
    },
    '.navBlockOpen': {
      position: 'fixed',
      left: '0rem',
      top: '0rem',
      width: '100%',
      height: '100vh',
      backdropFilter: 'blur(2px)',
      backgroundColor: 'rgb(30 24 6 / 70%)'
    },
    '.phoneSocialContainer': {
      width: '100%',
      maxWidth: 'unset',
      backgroundColor: 'primary',
      paddingBottom: '1rem'
    },
    '.socialIconsContainer, .phoneContainer': {
      '.smallNavHeading': {
        fontSize: ['1.1rem', '1.5rem'],
        fontFamily: 'subheading'
      },
      margin: '0rem',
      alignItems: 'center',
      justifyContent: 'center',
      color: 'light',
      border: 'none',
      width: '100%',
      fontSize: '1rem',
      maxWidth: 'unset',
      svg: {
        path: {
          fill: 'light'
        }
      }
    },

    '.containerScrolled': {
      backgroundColor: 'background',
      borderBottom: '3px solid',
      borderColor: 'secondary',
      zIndex: '1001',
      color: 'text',
      p: '0.5rem',
      '.hamburger > div': {
        backgroundColor: 'secondary'
      }
    },
    '.logoLocationContainer': {
      p: '0rem',
      ml: '1.5rem',
      mr: ['auto', '', '', '1.5rem']
    },
    '.logo': {
      p: '0rem',
      img: {
        opacity: '1',
        p: '0rem',
        maxHeight: ['75px', '100px', '120px', '150px']
      }
    },
    '.logoScrolled': {
      p: '0rem',
      img: {
        p: '0rem',
        opacity: '1',
        maxHeight: ['75px', '80px']
      }
    }
  },

  footer: {
    backgroundColor: 'background',
    display: 'flex',
    color: 'text',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
    padding: ['2rem 0.5rem 6rem', '', '2rem 3rem'],
    borderTop: 'solid 1px',
    borderColor: 'text',
    '.image': {
      display: 'none'
    },
    '.multiButtonContainer': {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      a: {
        width: 'fit-content',
        textTransform: 'uppercase',
        fontSize: '1.1rem',
        fontFamily: 'heading',
        color: 'inherit'
      }
    },
    '.contactDetails-container': {
      display: 'none'
    },
    '.copyright': {
      width: '50%',
      color: 'text',
      textAlign: 'center'
    },
    '.poweredByText': {
      margin: 'auto',
      color: 'text',
      fill: 'text'
    },
    '.socialIconsContainer': {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      marginBottom: '1rem',

      a: {
        padding: '0.5rem',
        color: 'text',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        ':hover': {
          backgroundColor: 'dark'
        },
        svg: {
          width: '30px',
          height: '30px'
        }
      }
    },
    '.gonationLogo': {
      filter: 'unset',
      width: '50%'
    }
  },

  ctaWidget: {},

  // ? ====================
  // ? ==== Reuseables ====
  // ? ====================

  title: {
    fontSize: ['2rem', '2.1rem', '2.25rem', '2.5rem', '2.75em'],
    fontWeight: '300',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    textAlign: 'inherit',
    marginBottom: '1.5rem',
    textTransform: 'uppercase',
    fontFamily: 'heading'
  },
  subtitle: {
    width: 'fit-content',
    textAlign: 'inherit',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: ['1.5rem', '1.75rem', '2rem', '2rem'],
    marginBottom: '1.5rem',
    border: 'none',
    color: 'text2',
    fontFamily: 'subheading',
    opacity: '0.8',
    fontWeight: '200'
  },
  text: {
    lineHeight: '1.75',
    fontSize: ['1rem', '', '1.1rem'],
    padding: '0.25rem 0rem',
    opacity: '0.6',
    p: {
      color: 'inherit',
      lineHeight: '2',
      textAlign: 'inherit',
      fontSize: ['1rem', '', '1.1rem']
    }
  },

  sideBySide1: {
    height: ['', '', '85vh', '85vh'],
    padding: ['1rem', '', '3rem'],
    backgroundColor: 'background',
    backgroundImage:
      'url(https://res.cloudinary.com/gonation/image/upload/v1699369230/sites/greenwich-pizzeria/AdobeStock_196911359-1.png)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '25%',
    backgroundPosition: ['95% 0%', '', '105% 95%', '105% 95%', '100% 95%'],
    margin: '0rem',
    '.lazyload-wrapper': {
      minHeight: '50vh',
      justifyContent: 'flex-start',
      width: ['', '', '60%', '60%'],
      zIndex: '10'
    },
    '.content': {
      position: 'relative',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      margin: ['1rem 0rem', '', '0rem'],
      width: ['', '', '40%', '40%'],
      zIndex: '1'
    },
    '.title': {
      variant: 'customVariants.title',
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      '::after': {
        content: "''",
        height: '3px',
        width: '100%',
        backgroundColor: 'primary',
        my: '1.5rem',
        position: 'relative',
        left: '-40%'
      }
    },
    '.subtitle': {
      variant: 'customVariants.subtitle'
    },
    '.text': {
      variant: 'customVariants.text',
      mb: '2rem',
      color: 'black',
      maxWidth: '480px'
    },
    a: {
      order: '5'
    },
    '.ctaButton': {
      variant: 'buttons.primary'
    }
  },

  sideBySide2: {
    variant: 'customVariants.sideBySide1'
  },

  // ? ====================
  // ? ====  Homepage  ====
  // ? ====================

  homepageHero: {
    backgroundColor: 'none',

    width: '100%',
    justifyContent: 'flex-start',

    position: 'relative',
    mb: ['6rem', '', '', '0rem'],

    '#preventMouseEffects': {
      background: 'none'
    },

    '.hero_content_container': {
      border: 'none',
      transform: ['', '', '', 'translateX(-50%)'],
      bottom: '0rem',
      left: '50%',
      justifyContent: 'center',
      alignItems: 'center',
      margin: '0rem auto',
      width: '100%',
      maxWidth: '767px',
      a: {
        alignSelf: ['center', 'center', 'center', 'center', 'center']
      }
    },

    '.title': {
      variant: 'customVariants.title',
      fontSize: ['2.5rem', '2.75rem', '3.25rem', '3.5rem', '3.75em'],
      mb: '0rem',
      textAlign: 'center'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle',
      fontSize: ['2rem', '2.1rem', '2.25rem', '2.5rem', '2.75em'],
      mb: '0.5rem',
      color: ['text', '', '', 'white']
    },
    '.text': {
      variant: 'customVariants.text'
    },
    a: {
      width: '100%',
      order: '4'
    },
    '.ctaButton': {
      variant: 'buttons.primary',
      mt: '0rem'
    }
  },
  homepageHeroShout: {
    display: 'none'
  },

  homepageShout: {
    backgroundColor: 'background',
    padding: ['0rem 1rem 1rem', '', '', '0rem 0.5rem 1rem'],
    flexWrap: ['wrap', 'wrap', 'wrap', 'wrap'],
    margin: '1rem 0rem 0rem',
    '.title': {
      border: ['', '', '', 'solid 0.5rem'],
      borderColor: ['', '', '', 'white'],
      backgroundColor: '#202325',
      width: ['', '', '80%'],
      padding: '1rem',
      textTransform: 'uppercase'
    },
    '.text': {
      fontFamily: 'heading',
      fontSize: ['1.76rem', '', '2.5rem'],
      order: ['4', '', '3'],
      border: ['', '', '', 'solid 0.5rem'],
      borderColor: ['', '', '', 'white'],
      fontWeight: 'normal',
      padding: ['', '', '1.5rem'],
      backgroundColor: ['primary', '', '', 'primary'],
      color: 'light'
    },
    '.date': {
      backgroundColor: 'secondary',
      border: ['', '', '', 'solid 0.5rem'],
      borderColor: ['', '', '', 'white'],
      fontSize: '0.8rem'
    },
    '.imageContainer': {
      border: ['', '', '', 'solid 0.5rem'],
      borderColor: ['', '', '', 'white'],
      order: ['', '', '4'],
      cursor: 'pointer'
    },
    '.shoutCTAS': {
      border: 'solid 8px',
      borderColor: 'background'
    }
  },

  homepageAbout: {
    variant: 'customVariants.sideBySide1',
    backgroundColor: 'backgroundTertiary',
    '.lazyload-wrapper': {
      width: ['', '', '40%'],
      '.image': {
        objectFit: 'cover',
        objectPosition: 'center'
      }
    },
    'div.content': {
      alignItems: ['flex-start', '', 'flex-start'],
      width: ['', '', '60%'],
      padding: ['1rem', '2rem', '3rem', '4rem', '5rem']
    }
  },

  disclaimer: {
    variant: 'customVariants.sideBySide1',
    margin: 0,
    padding: 0,
    height: 'auto'
  },

  homepageMenu: {
    variant: 'customVariants.sideBySide1',
    height: ['', '', '100vh', '100vh', '100vh', '100vh'],
    '.section': {
      height: 'fit-content',
      alignItems: ['center', 'center', 'center', 'center'],
      margin: 'auto',
      padding: ['2rem', '', '3rem', '4rem', '5rem'],
      backgroundColor: 'secondaryOpaque',
      '*': {
        color: 'white'
      },
      '.title': {
        variant: 'customVariants.title',
        mb: '0rem',
        '::after': {
          content: "''",
          height: '3px',
          width: '100%',
          backgroundColor: 'primary',
          my: '1.5rem',
          position: 'relative',
          left: '0%'
        }
      },
      '.text': {
        maxWidth: '500px',
        margin: 'auto',
        color: 'white',
        '*': {
          textAlign: 'center'
        }
      },
      '.linksContainer': {
        mt: '1.5rem',
        justifyContent: 'center',
        alignItems: ['center', 'center', 'center', 'center']
      },
      '.ctaButton': {
        variant: 'buttons.primary'
      }
    }
  },
  homepageBoxes: {
    padding: ['2rem', '', '', '3rem', '4rem', '5rem'],
    '.box': {
      width: ['100%', '', 'calc(50% - 3rem)', 'calc(50% - 3rem)'],
      margin: '1.5rem',
      transition: 'all ease-in-out 1s',
      borderRadius: '0px',
      textAlign: 'center',
      alignItems: 'center',
      ':hover': {
        '.image': {
          filter: 'unset'
        }
      },
      '.image': {
        // filter: 'grayscale(70%)',
        height: ['auto', '', '450px', '400px', '500px', '550px', '600px'],
        objectFit: 'cover',
        transition: 'all ease-in-out 0.5s'
      },
      '.title': {
        variant: 'customVariants.title',
        transition: 'all ease-in-out 1s',
        mt: '1rem',
        marginBottom: '0rem',
        padding: '0rem',
        paddingBottom: '0.5rem'
      },
      '.subtitle': {
        variant: 'customVariants.subtitle',
        fontWeight: '300'
      },
      '.text': {
        variant: 'customVariants.text',
        padding: '1rem 0rem 1rem',
        textAlign: 'center',
        maxWidth: '700px'
      },
      '.ctaLink': {
        variant: 'buttons.primary',
        margin: '0rem',

        ':hover': {
          backgroundColor: 'secondary',
          color: 'white',
          borderColor: 'white'
        }
      }
    }
  },
  homepageCatering: {
    variant: 'customVariants.homepageMenu'
  },

  // ? ========================
  // ? =====  About page  =====
  // ? ========================

  aboutSection: {
    padding: '20vh 2rem 20vh',
    '.title': {
      variant: 'customVariants.title',
      borderBottom: '2px solid',
      borderColor: 'primary'
    },
    '.text': {
      lineHeight: '2.5'
    }
  },

  AboutOwners: {
    variant: 'customVariants.sideBySide1',
    marginTop: '0rem',
    backgroundColor: '#e9e9e2',
    padding: '5rem 2rem 5rem',
    height: ['unset', 'unset', 'unset', 'unset', 'unset'],
    'div.content': {
      width: ['', '', '60%']
    },

    'div.lazyload-wrapper': {
      overflow: 'hidden',
      padding: ['', '', '2rem 3rem'],
      width: ['', '', '40%'],
      img: {
        objectFit: 'cover',
        objectPosition: 'top'
      }
    }
  },

  // ? =======================
  // ? =====  Menu page  =====
  // ? =======================

  menuCTAS: {
    padding: '3rem 1rem 0rem',

    '.ctaButton, .secondaryCtaButton': {
      variant: 'buttons.primary',
      borderRadius: '500px',
      margin: '0 auto'
    }
  },

  menu: {
    margin: '0rem 0rem',
    maxWidth: 'unset',
    padding: ['2rem 1rem', '', '3rem 2rem'],
    backgroundSize: 'auto',
    backgroundRepeat: 'repeat',

    '.backToMenuBtn': {
      variant: 'buttons.secondary',
      margin: '0rem auto 2rem 0rem'
    },
    '.dropdownContainer': {
      display: 'none'
    },
    '.section-cell': {
      width: ['100%', '', 'calc(50% - 1rem)'],
      borderRadius: '0px',
      position: 'relative'
    },
    '.allInContainerWrapper ': {
      maxWidth: '1200px',
      margin: '0rem auto'
    },
    '.cellImageContainer': {
      overflow: 'hidden'
    },

    '.cellImage': {
      borderRadius: '0px',
      transition: 'all ease-in-out 0.5s',
      filter: 'brightness(0.6)',
      ':hover': {
        transform: 'scale(1.1) translate(-50%, -50%)',
        transformOrigin: 'center center'
      }
    },
    '.menuCell': {
      borderRadius: '0px',
      border: 'double 15px black',
      '.imageFill': {
        paddingBottom: '60%'
      }
    },

    '.cellName': {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      color: 'light',
      margin: '0rem',
      fontSize: ['1.5rem', '', '1.75rem', '2.5rem']
    },

    '.tabsRow': {
      justifyContent: 'space-between',
      alignItems: 'flex-end',
      borderBottom: 'solid 2px black',
      borderTop: 'solid 2px black',
      marginBottom: ['2.5', '', '3.5rem'],
      padding: '1rem 1.5rem 1.5rem',
      '::before': {
        variant: 'customVariants.title',
        fontSize: ['1.5rem', '1.75rem', '2.5rem', '2.5rem', '3rem'],
        content: "'The Menu'",
        margin: ['0rem 0rem 1.5rem', '', '0rem auto 0rem 0rem'],
        width: ['100%', '', '', 'fit-content'],
        letterSpacing: '0px',
        textTransform: 'capitalize',
        padding: '1rem 0rem',
        fontFamily: 'heading',
        color: '#ff1616',
        textTransform: 'uppercase'
      },
      '.menuTab': {
        fontSize: '0.9rem',
        border: 'none',
        textTransform: 'capitalize',
        padding: '0.25rem',
        letterSpacing: '-1px',
        fontFamily: 'heading',
        height: 'fit-content',
        fontWeight: '300',
        backgroundColor: 'transparent',
        margin: 'auto 0.25rem',
        ':hover': {
          color: 'primary'
        }
      },
      '.menuTabActive': {
        color: 'text',
        borderBottom: '1px solid black'
      }
    },

    '.menuContainer': {
      paddingBottom: '0rem'
    },

    '.menuSectionTitle': {
      textAlign: 'left',
      margin: '1rem 0rem 2rem',
      fontSize: ['1.75rem', '2rem', '2.5rem', '3rem'],
      width: '100%',
      color: '#ff1616',
      borderBottom: 'dashed 3px',
      borderColor: '#ff1616',
      letterSpacing: '2px',
      textTransform: 'uppercase'
    },
    '.menuSectionDescription': {
      textAlign: 'left',
      fontSize: ['1rem', '1rem', '1.1rem', '1.2rem'],
      color: 'text',
      marginBottom: '2rem'
    },

    '.menuItemContainer, .menuItemContainerImgActive': {
      margin: '0rem 0rem 1rem',
      padding: ['0rem', '', '0rem 1.5rem 0rem 0rem']
    },

    '.menuItemInnerContainer': {
      margin: '0rem'
    },
    '.menuItemContentContainer': {
      margin: '0rem'
    },

    '.menuItemNamePriceContainer': {
      mb: '0.5rem'
    },

    '.menuItemName': {
      marginBottom: '0rem',
      fontFamily: 'heading',
      color: 'text',
      fontSize: ['1.1rem', '1.2rem', '1.3rem', '1.4rem']
    },
    '.menuItemDescription': {
      color: 'text',
      fontSize: ['1rem', '1rem', '1.1rem', '1.2rem'],
      whiteSpace: 'normal'
    },

    '.menuItemPrice': {
      marginBottom: '0rem',
      fontFamily: 'heading',
      color: 'text',
      fontSize: ['1.1rem', '1.2rem', '1.3rem', '1.4rem']
    }
  },

  // ? ========================
  // ? ====  Partners page  ====
  // ? ========================

  ourPartners: {},

  // ? ========================
  // ? ====  Gallery page  ====
  // ? ========================

  gallery: {
    '.albumTitle': {
      padding: '0.5rem'
    },
    '.sauces ': {
      '.albumImage': {
        objectFit: 'contain',
        gridRowEnd: 'span 2'
      }
    }
  },

  // ? ========================
  // ? ====  Events page  ====
  // ? ========================

  events: {
    '.events-container': {
      maxWidth: 'unset'
    },
    '.no-events-container': {
      padding: '10rem 1rem',
      img: {
        maxHeight: '70px'
        // filter: 'brightness(0) invert(1)',
      }
    },

    '.eventItemImage': {
      minHeight: 'unset',
      width: '25%'
      // height: '100%',
    },
    '.eventDays, .eventDate': {
      color: 'primary'
    },
    '.eventItemDescription': {
      maxHeight: '250px',
      overflowY: 'scroll',
      '::-webkit-scrollbar': {
        display: 'none'
      },
      '-ms-overflow-style': 'none' /* IE and Edge */,
      scrollbarWidth: 'none'
    }
  },

  // ? ========================
  // ? ====  Contact page  ====
  // ? ========================

  contactForm: {
    order: '4',
    h2: {
      variant: 'customVariants.title',
      order: 'unset',
      fontSize: ['1.75rem', '2rem', '2rem', '2.5rem'],
      textShadow: '2px 2px 15px white'
    },
    '.text': {
      color: 'black',
      textShadow: '2px 2px 15px white'
    },

    color: 'black',

    padding: ['3rem 1rem', '', '6rem 1rem'],
    form: {
      mt: '2.5rem',
      maxWidth: '500px'
    },
    '.inputField, .textField ': {
      fontWeight: '300',
      border: 'none',
      borderBottom: '1px solid',
      borderColor: '',

      backgroundColor: 'rgba(0,0,0,0.45)',
      borderRadius: '10px',
      padding: '1.5rem 0.5rem',
      color: 'white',
      fontFamily: 'heading',
      '::placeholder': {
        color: 'white'
      }
    },
    textarea: {
      '::placeholder': {
        color: 'text',
        fontWeight: '300',
        fontFamily: 'heading'
      }
    },

    '.submitBtn': {
      variant: 'buttons.secondary',
      padding: '1rem 2rem',
      height: 'unset',
      width: 'auto',
      borderRadius: '10px',
      marginTop: '1.5rem',
      borderColor: 'primary',
      ':hover': {
        borderColor: 'dark',
        backgroundColor: 'dark'
      }
    }
  },

  locationMap: {
    display: 'flex',
    flexDirection: ['column', 'column', 'column', 'column'],
    justifyContent: 'center',
    alignItems: 'center',
    order: '3',
    padding: '5vh 2rem',
    '.content_container': {
      padding: '0rem',
      justifyContent: 'flex-start'
    },

    h3: {
      variant: 'customVariants.title',
      justifyContent: 'center',
      display: 'flex',
      order: 'unset',

      borderBottom: '1px solid',
      padding: '1rem',
      mb: '2rem',
      textAlign: 'center',
      alignItems: 'center',
      color: 'text',
      marginBottom: '0rem !important'
    },
    '.contactDetails-container': {
      padding: '1rem 1rem 2rem',
      '.text': {
        fontSize: '0.9rem'
      }
    },
    '.hours-section': {
      maxWidth: 'unset',
      padding: '0rem',
      mb: '3rem !important',
      '.hoursContainer': {
        padding: ['1rem', '2rem', '3rem 4rem', '1rem 5rem'],
        '.dayContainer': {
          mb: '0.5rem'
        },
        '.dayofWeekText': {
          mb: '0rem',
          border: 'none'
        },
        '.timeBlocks': {
          justifyContent: 'flex-start'
        }
      }
    },
    '.dayofWeekText': {
      margin: '0 auto 1rem 0',
      borderBottom: '1px solid'
    },
    '.textContent': {
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      display: 'flex'
    }
  },

  // ? ========================
  // ? ====  Press page  ====
  // ? ========================

  pressMenu: {
    padding: ['1rem', '1rem', '1rem', '1rem'],
    backgroundColor: 'background',
    '.boxesHeading': {
      textAign: 'left',
      margin: '1rem',
      borderBottom: '2px solid',
      width: 'calc(100% - 2rem)',
      fontSize: ['2rem', '2.5rem', '2.5rem'],
      textAlign: 'left',
      justifyContent: 'flex-start'
    },

    '.boxesContainer': {
      justifyContent: 'flex-start'
    },
    '.box': {
      flexGrow: 1,
      maxWidth: '360px'
    },
    '.textContent': {
      backgroundColor: 'white',
      '.subtitle': {
        color: 'dark',
        fontSize: '1rem'
      }
    },
    '.imageContainer': {
      backgroundColor: 'white',
      img: {
        objectFit: 'cover',
        padding: '1rem'
      }
    }
  },

  pressPage: {
    backgroundColor: 'background'
  },

  // ? ============================================
  // ? ====  Private Events and Catering page  ====
  // ? ============================================

  privateEventsIntro: {
    padding: '20vh 2rem 20vh',
    background: 'background',
    '.title': {
      variant: 'customVariants.title',
      borderBottom: '2px solid',
      borderColor: 'primary'
    },
    '.text': {
      p: {
        lineHeight: '2.5'
      }
    }
  }
}
