export default {
  text: '#555555',
  text2: '#555555',
  primary: '#d74a4d',
  secondary: '#633f1b',
  secondaryOpaque: '#633f1bde',
  background: '#ffffff',
  backgroundPrimary: '#ffffff',
  backgroundSecondary: '#ffffff',
  backgroundTertiary: '#ffffff',
  light: '#FFF',
  dark: '#555555'
}
