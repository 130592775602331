export default {
  heading: 'Averia Sans Libre, sans-serif',
  subheading: 'Comfortaa, sans-serif',
  body: 'Dosis, sans-serif',
  body2: 'Tai Heritage Pro, serif',
  monospace: 'Menlo, monospace',
  display: 'Cabin Sketch, cursive',
  googleFonts: [
    'Open Sans:100,200,300,400,500,600,700,800,900',
    'Comfortaa:100,200,300,400,500,600,700,800,900',
    'Barlow:100,200,300,400,500,600,700,800,900',
    'Wire One',
    'Source Code Pro:200,300,400,500,600,700,800,900',
    'Tai Heritage Pro:100,200,300,400,500,600,700,800,900',
    'Dosis: 100,200,300,400,500,600,700,800,900',
    'Averia Sans Libre:100,200,300,400,500,600,700,800,900'
  ],
  customFamilies: ['Amelie', 'Corinthian Light', 'Futura', 'Avenir Next'],
  customUrls: [
    'https://www.gonation.biz/fonts/amelie/amelie.css',
    'https://www.gonation.biz/fonts/corinthian-light/corinthian-light.css',
    'https://www.gonation.biz/fonts/futura/futura.css',
    'https://www.gonation.biz/fonts/avenirnext/avenirnext.css'
  ]
}
